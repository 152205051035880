@use 'src/styles/sizes';
@use 'src/styles/timing';

.root {
  width: 100%;
  height: 100vh;

  background: url('../../images/bg-smol.gif') no-repeat center center fixed;
  background-size: cover;

  display: flex;
  justify-content: center;
  align-items: center;

  .main {
    width: sizes.$contentWidth;
    display: flex;
    flex-direction: column;

    .title {
      width: 100%;
      text-align: left;
      margin: 5px 0;
      margin-left: -15px;
      text-shadow: var(--default-text-shadow);
      text-transform: uppercase;
      letter-spacing: 2px;
    }

    .contentWrapper {
      min-height: calc(#{sizes.$contentWidth} + 40px);
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .content {
        height: sizes.$contentWidth;
        overflow: hidden;
        margin: sizes.$borderThickness * 2 0;
        transition: timing.$action;

        &.closed {
          height: 0;
          margin: 0;
        }

        &.shadow {
          box-shadow: var(--default-box-shadow);
        }
      }
    }

    .pageName {
      width: 50%;
      text-align: right;
      margin: 5px 0;
      overflow: hidden;
      transition: width timing.$action;
      white-space: nowrap;
      align-self: end;

      &.closed {
        width: 0;
      }

      h2 {
        width: 100%;
      }

    }
  }


}