@use 'src/styles/sizes';

$gridItemSize: calc(#{sizes.$contentWidth} / 3);

.root {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: auto auto auto;
  grid-auto-rows: $gridItemSize;
  grid-row: $gridItemSize;
  grid-row-gap: 5px;
  grid-column-gap: 5px;
  overflow-y: hidden;
  height: sizes.$contentWidth;

  > div {
    background-position: center;
    background-size: cover;
    box-shadow: var(--default-box-shadow);
  }
}

.thumbnail {
  cursor: pointer;
}

.modalWrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0.7);
  z-index: 999; // TODO: whyyyyy

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;

  &.closed {
    display: none;
    cursor: auto;
  }

  .modalInner {
    height: 90vh;
    width: 90vw;
    display: flex;
    justify-content: center;
    align-items: center;

    .image {
      max-height: 100%;
      max-width: 100%;
      box-shadow: var(--default-box-shadow);
    }
  }

  .navButton {
    position: absolute;
    font-size: 3rem;
    font-weight: bold;
    text-shadow: var(--default-box-shadow);
    color: var(--text-colour);
    cursor: pointer;

    background-color: transparent;
    border: none;
  }

  .closeButton {
    top: 0;
    right: 2rem;
  }

  .backButton, .nextButton {
    top: 50%;
    font-size: 5rem;
  }

  .backButton {
    left: 2rem;
  }

  .nextButton {
    right: 2rem;
  }

}