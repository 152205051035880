@use 'src/styles/sizes';

  .player {
    border: 0;
    width: sizes.$contentWidth;
    height: sizes.$contentWidth;

    &.hidden {
      width: 0;
      height: 0;
    }
}
