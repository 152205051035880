@use 'src/styles/sizes';
@use 'src/styles/timing';


.border {
  position: relative;
  width: calc(100% + #{sizes.$borderThickness * 6 });
  left: sizes.$borderThickness * -4;
  display: flex;
  flex-direction: column-reverse;
  margin: sizes.$borderThickness;
  pointer-events: none;

  &.top {
    flex-direction: column;
  }

  .topOrBottom {
    height: sizes.$borderThickness;
    background-color: white;
    position: absolute;
    left: 0;
    right: 0;
    // TODO: Get the box shadow here working
  }

  &:not(.top) {
    .topOrBottom {
      box-shadow: var(--default-box-shadow);
    }
  }

  .sides {
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 0;
    right: 0;

    > .side {
      background-color: white;
      width: sizes.$borderThickness;
      height: sizes.$borderThickness * 30;
      transition: height timing.$action;
      box-shadow: var(--default-box-shadow);

      &.closed {
        height: 0;
      }
    }
  }
}