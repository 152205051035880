@use '../../../styles/timing';

.root {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-right: calc(-1 * calc(0.5rem + 15px));

  button {
    background-color: transparent;
    border: none;
    color: #929292;
    font-size: 1.25rem;
    padding: .5rem;
    cursor: pointer;
    transition: color timing.$action;
    text-shadow: var(--default-text-shadow);
    font-family: FugazOne, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    white-space: nowrap;

    &.active {
      color: white;
    }
  }
}