.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 0;
  margin: 0;
  background-color: white;


  li {
    list-style: none;

    a {
      margin-top: 5px;
      padding: 5px 20px;
      font-size: 1.5rem;
      color: black;
      text-decoration: none;
      line-height: 2.5rem;
    }
  }
}
