@font-face {
  font-family: "Bangers";
  src: local('bangers'), url(./fonts/Bangers-Regular.ttf) format('truetype');
}
@font-face {
  font-family: "FugazOne";
  src: local('FugazOne-Regular'), url(./fonts/FugazOne-Regular.ttf) format('truetype');
}

body, button {
  --text-colour: white;
  --default-box-shadow: 2px 2px 2px 0 rgba(0,0,0,0.4);
  --default-text-shadow: 2px 2px 2px rgba(0,0,0,0.4);

  margin: 0;
  font-family: FugazOne, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
